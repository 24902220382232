// External
import { Text, Page, View, Document, usePDF } from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';
import { useUserInfo } from 'src/hooks';
import { PageNumber } from 'src/components/Pdf';

// Feature
import { useRecoupment } from '../api';
import { PayByCheck } from '../components/RecoupmentReport';

const RecoupmentDocument = ({ recoupment, user }) => {
  const date = new Date()
    .toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    .substring(0, 10);
  const dateFormatted = formatDate(date, 'MM/DD/YYYY', 'MMMM D, YYYY');

  const { mb_1, border_b, border_style_dashed, mt_3, mb_3 } = pdfStyles;

  const voucherInfo = recoupment?.[0].voucher_data?.[0];
  return (
    <Document>
      <Page size="LETTER" style={pdfStyles.pageDefault}>
        <Text style={mb_1}>{dateFormatted} </Text>
        <Text style={mb_1}>Name: {`${user.first_name} ${user.last_name}`}</Text>
        <Text style={mb_1}>PIN: {voucherInfo.pin}</Text>
        <View style={[border_b, border_style_dashed, mt_3, mb_3]} />
        <PayByCheck voucherInfo={voucherInfo} />
        <PageNumber />
      </Page>
    </Document>
  );
};

export const usePrintRecoupment = (caseId: string, pin: string) => {
  const { appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const location = useLocation();
  const path = location.pathname;
  let isOnRecoupmentPage = path.split('/').pop().includes('recoupment');
  // Data
  const { data: recoupment, error: recoupmentError } = useRecoupment(caseId, pin, isAppUserAgency);
  const { data: user, error: userError } = useUserInfo(pin, isAppUserAgency);

  const recoupmentReport = recoupment?.[0].recoupment_report;

  const isDataDefined = user !== undefined && recoupmentReport;

  const [instance, update] = usePDF({
    document: isDataDefined ? <RecoupmentDocument recoupment={recoupment} user={user} /> : null,
  });

  useEffect(() => {
    update(isDataDefined ? <RecoupmentDocument recoupment={recoupment} user={user} /> : null);
  }, [isDataDefined, recoupment, update, user]);

  let result = {
    displayRecoupmentPrintButton: isOnRecoupmentPage,
    data: {
      href: instance.url,
      download: `recoupment-history.pdf`,
    },
    error: recoupmentError || userError,
  };

  console.log('usePrintRecoupment', result);
  return result;
};
